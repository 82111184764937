/**********************************************************************************************************************/
/* TEASER */
/**********************************************************************************************************************/
.artist{
   margin: $gap/2 0;

   &__image{
      margin-bottom: rem-calc(20);
      position: relative;
      &__more{
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         background-color: rgba($primary-color, 0.75);
         display: flex;
         align-items: center;
         justify-content: center;
         opacity: 0;
         transition: opacity 0.25s ease-out;

         .icon{
            font-size: rem-calc(50);
            color: $secondary-color;
         }
      }
      &:hover .artist__image__more{
         opacity: 1;
      }
   }

   &__role{
      font-family: $body-font-family;
   }
}

/**********************************************************************************************************************/
/* ARTIST DETAIL */
/**********************************************************************************************************************/
.artist-detail{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   background-color: rgba($secondary-color, 0.8);
   z-index: 100;
   visibility: hidden;
   opacity: 0;
   transition: opacity 0.25s 0s ease-out, visibility 0s 0.26s linear;

   > .grid-container .grid-x{
      @include breakpoint(large up) {
         height: 100vh;
      }
   }

   &__inner{
      height: 100vh;
      overflow: auto;
      @include no-scrollbar();
   }

   &__close{
      position: absolute;
      right: 0;
      top: 0;
      padding: 35px;
      cursor: pointer;

      .icon{
         font-size: 24px;
      }
   }

   &__cell-1{
      z-index: -1;
   }

   &__content{
      overflow: auto;
      //@include no-scrollbar();

      @include breakpoint(large up) {
         max-height: rem-calc(350);
      }
   }

   &__role{
      display: block;
      margin-bottom: rem-calc(10);
   }

   &__social{
      a{
         margin: rem-calc(0 5 0 0);
         font-size: rem-calc(13);
      }
   }

   &.is-active {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s 0.1s ease-out, visibility 0s 0.0s linear;
   }
}