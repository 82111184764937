.footer {
   .menu {
      font-size: rem-calc(14);
      li {
         margin-right: rem-calc(10);
         a {
            padding: rem-calc(3 0);
         }
      }
   }

   &__logo {
      height: rem-calc(200);
      overflow: hidden;
      pointer-events: none;
      @include breakpoint(small down) {
         height: rem-calc(95);
         margin-right: rem-calc(-30);
      }

      svg {
         padding: rem-calc(20);
         width: rem-calc(725);
         height: rem-calc(725);
         fill: $primary-color;
         @include breakpoint(medium down) {
            width: rem-calc(400);
            height: rem-calc(400);
         }
      }
   }
}