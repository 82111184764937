.slick-slider{
   line-height: 1;
   &__arrows{
      font-size: rem-calc(50);
      line-height: 1;
   }
}

.slick-track{
   float: left;
}

.slider{
   &__slides{
      z-index: -2;
   }
}

.carousel{
   .slick-list{
      margin: rem-calc(0 -25);
   }

   .slick-slide{
      margin: rem-calc(0 25);
   }

   &__slides{
      @include breakpoint(medium up) {
         margin-left: calc(-8.34% + #{$gap/2}) !important;
      }
   }

   &__text-box{
      margin: 0 !important;
      z-index: 0 !important;
      @include breakpoint(small down) {
         text-align: center;
         &:before{
            background-color: transparent !important;
         }
         a {
            color: $white !important;
         }
      }
   }
}