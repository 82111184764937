.hero {
   margin-bottom: rem-calc(45);

   @include breakpoint(medium up) {
      margin-bottom: rem-calc(90);
   }

   &__inner {
      position: relative;
   }

   &__image {
      height: calc(100vh - 180px);
      background-size: cover;
      filter: grayscale(100%);

      &--label-yellow {
         @include teaser-bar($yellow);
      }

      &--label-purple {
         @include teaser-bar($purple);
      }

      &--label-lightblue {
         @include teaser-bar($lightblue);
      }

      &--label-pink {
         @include teaser-bar($pink);
      }

      &--reduced {
         height: calc(85vh - 180px);
      }
   }

   &__video {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      filter: grayscale(100%);
   }

   &__layer {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $blue;
      mix-blend-mode: soft-light;
   }

   &__logo{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
         width: rem-calc(400);
         height: rem-calc(400);
         fill: $primary-color;
         filter: drop-shadow( 0px 0px 8px rgba(0, 0, 0, .35));

         .single-events &{
            fill: $secondary-color;
         }
      }
   }

   &__text {
      margin-top: rem-calc(45);

      @include breakpoint(medium up) {
         margin-top: rem-calc(90);
      }

      &__label {
         margin-bottom: 1rem;
      }
   }
}