.gform {
   &_fields {
      margin: 0;
      list-style-type: none;
   }
}

.ginput_container {
   background-image: url('../images/line-white.png');
   background-repeat: no-repeat;
   background-size: 100% 5px;
   background-position: center bottom;

   &_checkbox{
      background-image: none;
   }

   .single-events &{
      background-image: url('../images/line-blue.png');
   }
}

.ginput_container_radio{
   background-image: none;
}

.gform_fields {
   flex-flow: row wrap;
   display: flex;
   margin-left: -$gap/2;
   margin-right: -$gap/2;
   list-style-type: none;

   > li {
      width: calc(100% - #{$gap});
      margin-left: $gap/2;
      margin-right: $gap/2;

      label {
         display: none;
      }

      @include breakpoint(large up) {

         &.large-12 {
            width: calc(50% - #{$gap});
         }
      }
   }
}

.gfield_checkbox,
.gfield_radio{
   list-style-type: none;
   margin: rem-calc(0 0 20 0);

   > li {
      display: flex;
      align-items: center;
      margin-bottom: rem-calc(5);

      input[type="checkbox"],
      input[type="radio"]{
         margin: 0;
      }

      label{
         display: block;
         color: $white;
         width: 100%;
      }
   }
}

.gform_footer {
   position: relative;
   line-height: 1;
   display: inline-block;

   &:before {
      content: '';
      display: inline-block;
      height: 3px;
      width: 12px;
      background-color: $primary-color;
      margin-right: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: height 0.2s 0.2s ease-out, width 0.2s 0s ease-out;
      z-index: -1;
   }

   &:hover:before{
      transition: height 0.2s 0s ease-out, width 0.2s 0.2s ease-out;
      height: 100%;
      width: 100%;
   }

   [type='submit']{
      padding: rem-calc(2 3 1 15);
      cursor: pointer;
      transition: color 0.25s 0.15s ease-out, padding 0.25s 0.15s ease-out;
      &:hover{
         color: $secondary-color;
         padding: rem-calc(2 4 1);
      }
   }
}

.gfield_label_before_complex,
.validation_message,
.validation_error,
.gform_ajax_spinner,
.gform_validation_container {
   display: none !important;
   position: absolute !important;
   left: -9000px;
}

/**********************************************************************************************************************/
/* FORMS IN GERNERAL */
/**********************************************************************************************************************/
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'],
[type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'],
[type='color'], textarea, select {
   // font-family: $header-font-family;
   font-size: rem-calc(20);

   .gfield_error & {
      color: $alert-color;
      &::placeholder {
         color: $alert-color;
      }
   }
}

textarea[rows] {
   resize: none;
   height: rem-calc(125);
}