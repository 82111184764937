@import 'themes/theme-basic';
@import 'settings';
@import 'imports';

@font-face {
  font-family: 'sofasanshand-bkdp';
  src: url('../fonts/sofasanshand-bkdp-webfont.woff2') format('woff2'),
       url('../fonts/sofasanshand-bkdp-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'queulatcnd-bold';
  src: url('../fonts/queulatcnd-bold-webfont.woff2') format('woff2'),
       url('../fonts/queulatcnd-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'queulatcnd-light';
  src: url('../fonts/queulatcnd-light-webfont.woff2') format('woff2'),
       url('../fonts/queulatcnd-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
