.header {
   position: relative;
   z-index: 100;

   .logo {
      position: fixed;
      display: block;
      left: rem-calc(10);
      top: rem-calc(10);
      width: rem-calc(140);
      height: rem-calc(107);
      fill: $primary-color;
      padding: rem-calc(15);
      @include breakpoint(small down) {
         top: rem-calc(15);
         width: rem-calc(120);
         height: rem-calc(91);
      }

      g, path, svg{
         overflow: visible;
      }
   }

   .hamburger {
      position: fixed;
      display: block;
      right: 0;
      top: 0;
      cursor: pointer;
      padding: rem-calc(24);

      svg{
         height: rem-calc(45);
         width: rem-calc(45);
         fill: $primary-color;
      }
   }

   .newsletter-register {
      position: fixed;
      display: block;
      left: 0;
      bottom: 0;
      line-height: 0;
      font-size: rem-calc(68);
   }

   .music {
      position: fixed;
      display: block;
      right: 0;
      bottom: 0;
      line-height: 0;
      font-size: rem-calc(90);
   }

   .top-bar {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem-calc(13);
      @include breakpoint(small down) {
         height: 75px;
      }
   }

   .side-bar {
      position: fixed;
      display: flex;
      right: 0;
      top: rem-calc(90);
      width: rem-calc(90);
      height: calc(100vh - 180px);
      align-items: center;
      justify-content: center;

      ul {
         min-width: calc(100vh - 180px);
         transform: rotate(90deg);
         justify-content: center;
         font-size: rem-calc(13);
         li {
            margin: rem-calc(0 5);
         }
      }
   }
}

.navigation {
   position: fixed;
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   background-color: rgba($secondary-color, 0.95);
   z-index: 99;
   padding: rem-calc(125 0 60);
   visibility: hidden;
   opacity: 0;
   transition: opacity 0.25s 0s ease-out, visibility 0s 0.26s linear;

   @include breakpoint(medium up) {
      padding: rem-calc(90 0);
   }

   > .grid-container {
      width: 100%;
   }

   &__inner{
      height: calc(100vh - 180px);
   }

   &__teaser {
      height: 100%;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue;
      padding: $gap;

      &--yellow{
        background-color: $yellow;
      }

      &--purple{
         background-color: $purple;
      }

      &--lightblue{
         background-color: $lightblue;
      }

      &--pink{
         background-color: $pink;
      }

      &__logo {
         svg{
            width: rem-calc(250);
            height: rem-calc(250);
            fill: $blue;
         }
      }
      &__link {
         a {
            color: $blue;
            &:before{
               background-color: $blue;
            }
            &:hover{
               color: $light-gray;
            }
         }
      }
   }

   &.is-active {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s 0.1s ease-out, visibility 0s 0.0s linear;
   }

   .menu {
      li {
         a {
            font-family: $header-font-family;
            font-size: rem-calc(28);

            @include breakpoint(medium up) {
               font-size: rem-calc(38);
            }
         }
      }
   }
}
