.teaser {
   &__image {
      z-index: -1;
   }

   &__content {
      @include breakpoint(medium down) {
         margin: rem-calc(-30 15 0);
      }

      &__links{
         .more-link + .more-link{
            margin-left: rem-calc(10);
         }
      }
   }


   &--yellow .teaser__image{
      @include teaser-bar($yellow);
   }

   &--purple .teaser__image{
      @include teaser-bar($purple);
   }

   &--lightblue .teaser__image{
      @include teaser-bar($lightblue);
   }

   &--pink .teaser__image{
      @include teaser-bar($pink);
   }
}
