/**********************************************************************************************************************/
/* VARS */
/**********************************************************************************************************************/
$gap: rem-calc(map-get($grid-margin-gutters, 'small'));

$blue: #003043;
$yellow: #FBC51E;
$purple: #9C6DA7;
$lightblue: #61B4C8;
$pink: #EF839A;

/**********************************************************************************************************************/
/* MIXINS */
/**********************************************************************************************************************/
@mixin teaser-bar($color) {
   position: relative;
   &:before{
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      height: rem-calc(8);
      width: 100%;
      background-color: $color;
      z-index: 1;
   }
}

@mixin no-scrollbar() {
   scrollbar-width: none;
   -ms-overflow-style: none;

   &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
   }
}

/**********************************************************************************************************************/
/* STYLE */
/**********************************************************************************************************************/
html, body {
   @include breakpoint(1600px up) {
      font-size: 18px;
   }
}

.grid-container {
   @include breakpoint(small down) {
      max-width: calc(100% - 40px);
   }
}

.cell {
   &--inset {
      @include breakpoint(large up) {
         margin-left: calc(-8.34% + #{$gap/2}) !important;
      }
   }
}

img{
   width: 100%;
}

svg.line{
   height: 100%;
   max-height: 6px;
   width: 100%;
}

audio{
   display: none;
}