.newsletter{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   background-color: rgba($secondary-color, 0.95);
   z-index: 100;
   visibility: hidden;
   opacity: 0;
   transition: opacity 0.25s 0s ease-out, visibility 0s 0.26s linear;

   .grid-x{
      height: 100vh;
   }

   &__close{
      position: absolute;
      right: 0;
      top: 0;
      padding: 35px;
      cursor: pointer;

      .icon{
         font-size: 24px;
      }
   }

   &.is-active {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s 0.1s ease-out, visibility 0s 0.0s linear;
   }
}