.pagination{
   text-align: center;
   .menu{
      display: inline-flex;
      li{
         a, span{
            padding: rem-calc(10 15 7);
            line-height: 1;
            display: inline-block;
            font-size: rem-calc(32);
            font-family: $header-font-family;

         }
         &.is-active span{
            background-color: $primary-color;
            color: $secondary-color;
         }
      }
   }
}