.no-browser-support{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 999999;
  display: none;

  &--container {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }

  .no-flexboxtweener.no-flexbox & {
    display: table;
  }
}