.page-teaser{
   &__image{
      z-index: -2;
      position: relative;
   }
   &__text{
      margin-left: 10%;
      margin-right: 10%;
      margin-top: rem-calc(-70);
   }
}