$generate-gap-classes: (margin-top, margin-bottom, padding-top, padding-bottom);
$gap-range: (0, 15);

@mixin generate-gap-classes($prefix, $attr, $from, $to) {
   @for $i from $from through $to {
      @if $prefix == '' {
         .#{$attr}-#{$i} {
            #{$attr}: #{$i}rem;
         }
      } @else {
         .#{$prefix}-#{$attr}-#{$i} {
            #{$attr}: #{$i}rem;
         }
      }
   }
}

$from: nth($gap-range, 1);
$to: nth($gap-range, 2);

@each $gap-attr in $generate-gap-classes {
   //@include generate-gap-classes('', $gap-attr, $from, $to);
   @include -zf-each-breakpoint {
      @include generate-gap-classes($-zf-size, $gap-attr, $from, $to)
   }
}


