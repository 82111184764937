/**********************************************************************************************************************/
/* COOKIES */
/**********************************************************************************************************************/
.cookies {
   position: fixed;
   bottom: 0;
   width: 100%;
   color: $secondary-color;
   z-index: 999;
   padding: $gap/2 0;
   font-size: rem-calc(14);

   a {
      color: $secondary-color;
   }
}

/**********************************************************************************************************************/
/* TEXT BOX */
/**********************************************************************************************************************/
.text-box {
   position: relative;
   padding: rem-calc(24);
   color: $secondary-color;
   z-index: 1;

   @include breakpoint(medium up) {
      padding: $gap*2;
   }

   @include breakpoint(medium down) {
      margin: rem-calc(-30 15 0);
   }

   a {
      color: $secondary-color;

      &.more-link {
         &:before {
            background-color: $secondary-color;
         }
         &:hover {
            color: $primary-color;
         }
      }
   }

   &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      z-index: -1;
      right: 0;
      width: 100%;
      background-color: rgba($primary-color, 0.90);
   }
}

/**********************************************************************************************************************/
/* INTRODUCTION */
/**********************************************************************************************************************/
.introduction {
   &__cell-2 {
      z-index: -2;
   }
}

/**********************************************************************************************************************/
/* FILTER */
/**********************************************************************************************************************/
.filter {
   @include breakpoint(small down) {
      display: block;
   }
   li {
      @include breakpoint(small down) {
         display: block;
         text-align: center;
      }
      &.is-active {
         a {
            display: inline-block;
            background-color: $primary-color;
            color: $secondary-color;
         }
      }
   }
}

/**********************************************************************************************************************/
/* EVENT */
/**********************************************************************************************************************/
.event {
   &__programm {
      svg {
         fill: $primary-color;
      }
      &__item {
         padding: rem-calc(20 0 10);

         &__date {
            @extend .category-label;
         }

         &__artists {
            display: none;
            padding: rem-calc(30 0);
         }

         &__more {
            font-size: rem-calc(28);
            line-height: 0;
            display: inline-block;
         }
      }
   }
   &__sponsors {
      &__logo {
         position: relative;
      }
      &__main {
         display: block;
         position: absolute;
         top: 0;
         line-height: 1rem;
         width: 100%;
      }
   }
}

/**********************************************************************************************************************/
/* GALLERY */
/**********************************************************************************************************************/
.gallery {
   &__image {
      margin: $gap/2;
   }
}

/**********************************************************************************************************************/
/* GOOGLE MAP */
/**********************************************************************************************************************/
#map {
   height: 350px;
   @include breakpoint(medium up) {
      height: 450px;
   }
   @include breakpoint(large up) {
      height: 550px;
   }
}