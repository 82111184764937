@import 'foundation';
@import 'motion-ui';
@import url("https://d1azc1qln24ryf.cloudfront.net/26008/KlassikNuevo/style-cf.css?dj0chi");

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
//@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/
@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';

// Global
@import "global/base";
@import "global/accessibility";
//@import "global/animations";
@import "global/gaps";
@import "global/gravity-forms";
@import "global/no-browser-support";
@import "global/wp-overrides";
@import "global/typography";

// Components
@import "components/buttons";
//@import "components/hamburgers";
@import "components/links";
@import "components/pagination";
@import "components/social";

// Modules
@import "modules/artist";
@import "modules/fancybox";
@import "modules/navigation";
@import "modules/newsletter";
@import "modules/hero";
@import "modules/page-teaser";
@import "modules/slider";
@import "modules/teaser";
@import "modules/teaser-small";
@import "modules/footer";

// Farner
@import "style";
