.teaser-small {
   &__content {
      margin-top: rem-calc(20);
      padding: rem-calc(0 10);
   }

   &__image {
      position: relative;

      &__more {
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         background-color: rgba($primary-color, 0.75);
         display: flex;
         align-items: center;
         justify-content: center;
         opacity: 0;
         transition: opacity 0.25s ease-out;

         .icon {
            font-size: rem-calc(50);
            color: $secondary-color;
         }
      }

      &:hover .teaser-small__image__more {
         opacity: 1;
      }
   }

   &--yellow {
      .teaser-small__image__more {
         background-color: rgba($yellow, 0.75);
      }
      .teaser-small__image {
         @include teaser-bar($yellow);
      }
   }

   &--purple {
      .teaser-small__image__more {
         background-color: rgba($purple, 0.75);
      }
      .teaser-small__image {
         @include teaser-bar($purple);
      }
   }

   &--lightblue {
      .teaser-small__image__more {
         background-color: rgba($lightblue, 0.75);
      }
      .teaser-small__image {
         @include teaser-bar($lightblue);
      }
   }

   &--pink {
      .teaser-small__image__more {
         background-color: rgba($pink, 0.75);
      }
      .teaser-small__image {
         @include teaser-bar($pink);
      }
   }
}
