p, .button {
   &:last-child {
      margin: 0;
   }
}

h1, h2, h3, h4, h5, h6 {
   strong, b {
      font-family: 'sofasanshand-bkdp', serif;
   }
}

strong, b {
   font-family: 'queulatcnd-bold', serif;
}

address {
   font-style: normal;
   display: inline-block;
}

a {
   font-family: 'queulatcnd-bold', serif;

   &.more-link {
      padding: rem-calc(2 3 1 15);
      position: relative;
      line-height: 1;
      display: inline-block;
      transition: color 0.25s 0.15s ease-out, padding 0.25s 0.15s ease-out, margin-right 0.25s 0.15s ease-out;;
      z-index: 2;

      &:before {
         content: '';
         display: inline-block;
         height: 3px;
         width: 12px;
         background-color: $primary-color;
         margin-right: 3px;
         position: absolute;
         left: 0;
         bottom: 0;
         transition: height 0.2s 0.2s ease-out, width 0.2s 0s ease-out;
         z-index: -1;
      }

      &:hover{
         color: $secondary-color;
         padding: rem-calc(2 4 1);
         margin-right: rem-calc(10);

         &:before{
            transition: height 0.2s 0s ease-out, width 0.2s 0.2s ease-out;
            height: 100%;
            width: 100%;
         }
      }
   }
}

.category-label {
   display: block;
   margin-bottom: rem-calc(10);
   font-size: rem-calc(13);
}

.lead{
   @include breakpoint(medium down) {
      font-size: rem-calc(18);
   }
}
